import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  Grid,
  Paragraphs,
  HalvedContent,
  ContentBox,
  List,
  Dialog,
  SectionHead
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-dienstleistungen/versicherungsmathematische-dienstleistungen';

const VersicherungsmathematischeDienstleistungenPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: "75"
    }}
  >
    <section>
      <Grid>
        <div className="children-margin">
          <Paragraphs items={content.leftParagraphs} />
        </div>
        <div className="children-margin">
          <Paragraphs items={content.rightParagraphs} />
        </div>
      </Grid>
    </section>
    <section className="no-children-margin">
      <HalvedContent id="versicherungsmath-gutachten" swapped>
        <BackgroundImage
          fluid={data.womanSmilingOutside.childImageSharp.fluid}
        />
        <ContentBox
          background="light-grey-blue"
          headline={content.gutachten.headline}
          align="right"
        >
          <p>{content.gutachten.paragraph}</p>
          <List items={content.gutachten.listItems} />
          <Dialog
            buttonText={content.gutachten.dialog.buttonText}
            buttonVariant={content.gutachten.dialog.buttonVariant}
            title={content.gutachten.dialog.title}
          >
            <Paragraphs items={content.gutachten.dialog.paragraphs} />
          </Dialog>
        </ContentBox>
      </HalvedContent>
      <HalvedContent id="aktuarielle-dienstleistungen">
        <BackgroundImage fluid={data.womanOnCouch.childImageSharp.fluid} />
        <ContentBox
          background="light-pink"
          headline={content.aktuarielleThemen.headline}
          align="left"
        >
          <Paragraphs items={content.aktuarielleThemen.paragraphs} />
          <List items={content.aktuarielleThemen.listItems} />
          <Dialog
            buttonText={content.aktuarielleThemen.dialog.buttonText}
            buttonVariant={content.aktuarielleThemen.dialog.buttonVariant}
            title={content.aktuarielleThemen.dialog.title}
          >
            <Paragraphs items={content.aktuarielleThemen.dialog.paragraphs} />
            <ul>
              {content.aktuarielleThemen.dialog.listItems.map(
                (listItem, index) => (
                  <li key={index} className="mb-0">
                    {listItem}
                  </li>
                )
              )}
            </ul>
            <p>{content.aktuarielleThemen.dialog.bottomText}</p>
          </Dialog>
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="auslagerung-von-pensionszusagen">
      <SectionHead headline={content.pensionszusagen.headline}>
        <Paragraphs items={content.pensionszusagen.paragraphs} innerHTML />
      </SectionHead>
    </section>
    <section className="bg-light-blue">
      <SectionHead headline={content.strategien.sectionHead.headline}>
        <p>{content.strategien.sectionHead.paragraph}</p>
      </SectionHead>
      <Grid>
        {content.strategien.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader headline={card.headline} color="dark-blue" parallel>
              <card.icon />
            </CardHeader>
            <p>{card.paragraph}</p>
            <Dialog
              buttonText={card.dialog.buttonText}
              buttonVariant={card.dialog.buttonVariant}
              title={card.dialog.title}
            >
              <Paragraphs items={card.dialog.paragraphs} />
            </Dialog>
          </Card>
        ))}
      </Grid>
    </section>
    <section>
      <ContentBox
        background="light-blue"
        headline={content.vorteile.headline}
        single
      >
        <List items={content.vorteile.listItems} columns={2} />
      </ContentBox>
    </section>
  </MainLayout>
);

export default VersicherungsmathematischeDienstleistungenPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-standing-and-holding-tablet-wide.jpg" }
    ) {
      ...image
    }
    womanSmilingOutside: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiling-outside.jpg" }
    ) {
      ...image
    }
    womanOnCouch: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-sitting-with-laptop-on-couch.jpg" }
    ) {
      ...image
    }
  }
`;
