import HistogramIcon from 'src/assets/icons/histogram.svg';
import CenterOfGravityIcon from 'src/assets/icons/center-of-gravity.svg';

const content = {
  title: 'Versicherungsmathematische Dienstleistungen',
  headvisualHeadline: 'Versicherungsmathematische Dienstleistungen',
  leftParagraphs: [
    'Rund um das Thema Pensionszusagen (unmittelbare Pensionsverpflichtungen) besteht regelmäßig ein hoher Beratungsbedarf. Dieser kann in allen Facetten von unserem Team hoch spezialisierter Versicherungsmathematiker der NBB abgebildet werden.',
    'Die gesetzlichen Anforderungen zum bilanziellen Ausweis dieser Verpflichtungen nehmen stetig zu. Die benötigten Bilanzwerte können Sie im Rahmen der Erstellung eines versicherungsmathematischen Gutachtens in Auftrag geben.',
    'Die Auslagerung der Verpflichtungen auf externe Versorgungsträger wie Pensionsfonds und Unterstützungskassen ist in Niedrigzinszeiten und einhergehenden hohen bilanziellen Belastungen für immer mehr Unternehmen eine wirtschaftlich interessante Alternative.'
  ],
  rightParagraphs: [
    'Gerne unterstützt Sie die NBB bei der Analyse der Bilanz- und Liquiditätsauswirkungen in Ihrem Unternehmen. Neben den Bilanzwerten gehen auch andere aktuarielle Erfordernisse mit Pensionszusagen einher. Sei es bei einer Scheidung eines Mitarbeiters mit zugehörigem Versorgungsausgleich, bei Abfindungen von Versorgungsansprüchen oder auch bei Ausscheiden eines Mitarbeiters, um nur exemplarisch Vorkommnisse zu nennen, die aktuarielle Berechnungen erforderlich machen.',
    'Alle versicherungsmathematische Dienstleistungen werden mit höchster Qualität, termintreu zu attraktiven Preisen angeboten.'
  ],
  gutachten: {
    headline: 'Versicherungsmath. Gutachten',
    paragraph:
      'Alle benötigten Werte zum Bilanzausweis von unmittelbaren Pensionsverpflichtungen (Direktzusagen, Pensionszusagen) sind in den versicherungsmathematischen Gutachten der NBB dargestellt. Diese können auch für folgende weitere regelmäßig vorkommende Verpflichtungen beauftragt werden:',
    listItems: [
      'mittelbaren Pensionsverpflichtungen (reservepolsterfinanzierte Unterstützungskassen)',
      'Jubiläumsverpflichtungen',
      'Altersteilzeitverpflichtungen'
    ],
    dialog: {
      buttonText: 'Mehr erfahren',
      buttonVariant: 'gray-blue',
      title: 'Versicherungsmath. Gutachten',
      paragraphs: [
        'Auch für weniger häufig vorkommende Verpflichtungen wie z.B. Kaufpreisrentenverpflichtungen Überbrückungsgeldverpflichtungen oder auch Wertkontenverpflichtungen können Gutachten erstellt werden.',
        'Die Bewertung kann nach handels- und steuerrechtlichen wie auch internationalen (z. B. IFRS oder US-GAAP) Rechnungslegungsgrundsätzen durchgeführt werden.',
        'Es sind regelmäßig alle für die Bilanzierung benötigten Werte im Gutachten ausgewiesen. Neben handelsrechtlichen Erfüllungsbetrag, steuerrechtlichen Teilwert, oder auch DBO nach IAS bzw. ABO und PBO nach FAS sind auch alle relevanten Werte für die Gewinn- und Verlustrechnung wie  beispielsweise Zinsaufwand und Zinsänderungseffekt sowie die erforderlichen Anhangsangaben enthalten.'
      ]
    }
  },
  aktuarielleThemen: {
    headline: 'Aktuarielle Themen',
    paragraphs: [
      'Neben den Bilanzwerten gehen auch andere aktuarielle Erfordernisse mit Pensionszusagen einher. Diese sind sehr vielfältig und unterschiedlich motiviert.',
      'Für eine aussagekräftige Unternehmensplanung unerlässlich sind:'
    ],
    listItems: [
      'Prognoseberechnungen über die Entwicklung von Verpflichtungswerten und Rückstellungsverläufen',
      'Finanzierungsanalysen im Rahmen von bestehenden Pensionsverpflichtungen'
    ],
    dialog: {
      buttonText: 'Mehr erfahren',
      buttonVariant: 'gray-blue',
      title: 'Aktuarielle Themen',
      paragraphs: [
        'Auch bei Gestaltung und Umstrukturierung von Versorgungszusagen ist aktuarielles Know-How gefragt.',
        'Ebenso verhält es sich bei gängigen Ereignissen im Erwerbleben eines Mitarbeiters:'
      ],
      listItems: [
        'Laufende Standmitteilungen zur Höhe der Versorgungsansprüche im laufenden Arbeitsverhältnis',
        'Versorgungsausgleich-Berechnungen bei Scheidung eines Mitarbeiters ',
        'Ermittlung von Rentenansprüchen und unverfallbaren Anwartschaften bei ausscheiden eines Mitarbeiters',
        'Ermittlung von Abfindungs- und Übertragungswerten bei Auflösung von Verpflichtungen',
        'Anpassungsprüfung von laufenden Rentenleistungen gemäß § 16 Betriebsrentengesetz'
      ],
      bottomText:
        'All diese Dienstleistungen können bei der NBB in Auftrag gegeben werden.'
    }
  },
  pensionszusagen: {
    headline: 'Auslagerung von Pensionszusagen',
    paragraphs: [
      '<strong>Machen Sie Ihre Bilanz zukunftssicher</strong>',
      'Die Bilanz ist das Aushängeschild Ihres Unternehmens. Pensionsverpflichtungen werden dabei besonders kritisch gesehen, denn sie gelten als schwer kalkulierbare Belastung. Mit der NÜRNBERGER Pensionsfonds AG und der NBB ist eine liquiditätsschonende Auslagerung Ihrer Versorgungsverpflichtungen möglich. Eine Auslagerung von erdienten Pensionsverpflichtungen auf einen externen Träger ermöglicht eine vollständige Bilanzbereinigung unter Aufrechterhalten der bAV der Versorgungsberechtigten.'
    ]
  },
  strategien: {
    sectionHead: {
      headline: '2 Strategien zur Wahl – so wie es für Sie passt.',
      paragraph:
        'Für die Auslagerung auf den NÜRNBERGER Pensionsfonds gibt es 2 Anlagealternativen: Das innovative Zielrenditesystem oder die Anlage mit fest definierten Anlageklassen. Sie entscheiden!'
    },
    cards: [
      {
        icon: HistogramIcon,
        headline: 'Strategie 1 – Anlage mit fest definierten Anlageklassen',
        paragraph:
          'Diese Variante ist für Anleger geeignet, die die Risiken der Kapitalanlage über die Vertragsdauer hinweg anhand fest definierter Anlageklassen und vereinbarter Mini- bzw. Maximalhöhe festlegen wollen.',
        dialog: {
          buttonText: 'Mehr lesen',
          buttonVariant: 'gray-blue',
          title: 'Anlage mit fest definierten Anlageklassen',
          paragraphs: [
            'Diese Variante ist für Anleger geeignet, die die Risiken der Kapitalanlage über die Vertragsdauer hinweg anhand fest definierter Anlageklassen und vereinbarter Mini- bzw. Maximalhöhe festlegen wollen.',
            'Eine Umschichtung seitens des Pensionsfonds ist nur innerhalb der fest vereinbarten Assetklassen und der vereinbarten Grenzwerte möglich. Somit kann insbesondere der Höchstanteil der Aktien durch die Wahl der Anlagestrategie gesteuert werden.'
          ]
        }
      },
      {
        icon: CenterOfGravityIcon,
        headline: 'Strategie 2 – Zielrenditesystem',
        paragraph:
          'Diese Variante ist vor allem für Anleger geeignet, deren Fokus vorrangig auf dem Erreichen der angestrebten Zielrendite liegt.',
        dialog: {
          buttonText: 'Mehr lesen',
          buttonVariant: 'gray-blue',
          title: 'Zielrenditesystem',
          paragraphs: [
            'Diese Variante ist vor allem für Anleger geeignet, deren Fokus vorrangig auf dem Erreichen der angestrebten Zielrendite liegt.',
            'Dabei ist der Pensionsfonds in der Anlage der Gelder frei. Abhängig von der Kapitalmarktlage erfolgen regelmäßig Umschichtungen der Anlageklassen mit dem Ziel, die anvisierte Zielrendite möglichst zu erreichen. Insbesondere der Anteil der Aktien und aktienähnlichen Anlageklassen kann dadurch während der Vertragslaufzeit stark variieren.'
          ]
        }
      }
    ]
  },
  vorteile: {
    headline: 'Ihre Vorteile kurz und knapp',
    listItems: [
      'Die unternehmensseitigen Versorgungsrisiken (Langlebigkeit, Tod, Invalidität) werden auf einen externen Versorgungsträger übertragen',
      'Die Verringerung des Personalaufwands und des Verwaltungsaufwands',
      'Sofortige Verbesserung der Bilanzkennzahlen und Erhöhung der Kreditwürdigkeit',
      'Eine Reduzierung der Insolvenzsicherungskosten beim Pensions-Sicherungs-Verein um 80 %',
      'Eine verbesserte Verhandlungsposition bei anstehendem Unternehmensverkauf bzw. bei Nachfolgeregelung',
      'Die Erhöhung der Anlagerenditen aufgrund von Steuerfreiheit der Kapitalanlageerträge des Pensionsfonds'
    ]
  }
};

export default content;
